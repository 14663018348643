/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-06-05 15:28:21
 * @LastEditors: bbqin
 * @LastEditTime: 2021-07-16 15:45:10
 */
import React from 'react';
import { useSimpleTable } from './store';
import { Table } from '@platform/base';
import { BaseComponent } from '@platform/template';
import { lib, handleSortcolumns } from '@platform/table-core';
import { ArrayData } from '@platform/template';
import { isFunction, isNumber, withViewModel, warningOnce, pageTo, cacheTools, getMultiLang, ajax } from '@platform/api';
import { utils } from '@platform/table-core';
const { isArray, isObject, excelPackageData, getMetaIsTotal, isUndefined, setPageSizeStorage, getPageSizeStorage, uuidv4 } = utils;
const { formatColumn, pagination, selectRow, indexCol, checkCol, errorCol, filter, selectable, simpleTableModal, total, columnSort, contextMenu, hoverContent } = lib;
require('./index.less');
const MetaTable = contextMenu(simpleTableModal(pagination(selectable(filter(selectRow(indexCol(checkCol(errorCol(hoverContent(total(formatColumn(columnSort(Table)))))))))))));

// 表格数据
function TableData(data, keyPath = 'rowid') {
    return new ArrayData(data, keyPath);
}
@withViewModel
class SimpleTable extends BaseComponent {
    constructor(props) {
        super(props);
        this.ViewModel = this.props.ViewModel;
        this.state = { 
            isEllipsis: true,
            //key: 1 
        };
        this.key = 1;
        this.tableScrollTop = 0;
        this.setScrollFlag = false;

        this.uuid = uuidv4();
        typeof window === 'object' && (window[this.uuid] = props.store);
    }
    UNSAFE_componentWillMount() {
        //初始化表格的时候,将缓存数据放入表格中
        let { config = {}, store, afterLangload } = this.props;
        if (config.dataSource) {
            let data = this.ViewModel.getData(config.dataSource);
            if (data && data.simpleTable) {
                let arr = ['rows', 'pageInfo', 'allpks', 'alltss', 'props', 'modelDatas', 'sortParam', 'config', 'checkInfo', 'scrollTop']
                arr.forEach(item => {
                    if (item === 'rows') {
                        let val = isArray(data.simpleTable.rows)
                            ? new TableData(data.simpleTable.rows, 'rowId')
                            : store.getData();
                        store.setData(val, false);
                    } else if (item === 'pageInfo') {
                        let val = data.simpleTable[item]
                            ? data.simpleTable[item]
                            : store.getStore([item]);
                        let allpks = data.simpleTable['allpks'] || []
                        let total = allpks.length
                        if (total) { val.total = total } //用于更新卡片跳列表，更新总条数
                        store.setStore([item], val, false);
                    } else if (item === 'scrollTop') {
                        let val = data.simpleTable[item]
                            ? data.simpleTable[item]
                            : store.getStore([item]);
                        this.tableScrollTop = val;
                    } else {
                        let val = data.simpleTable[item]
                            ? data.simpleTable[item]
                            : store.getStore([item]);
                        if (item === 'checkInfo') {
                            store.setStore([item], val, true); //true 最后更新store
                        } else {
                            store.setStore([item], val, false);
                        }
                    }
                })
                //执行数据初始化周期函数
                if (isFunction(config.componentInitFinished)) {
                    config.componentInitFinished();
                }
                // 兼容筛选, 当卡片返回列表时, 重新筛选列表
            }
        }

        let { setCache } = store;

        //加载多语
        let callback = json => {
            // this.setState({ json });
            setCache('langJson', json || {});
            // console.log(store.getCache('langJson'))
            // 备份一份到全局
            afterLangload && afterLangload(json, store);

            // 强制更新一次
            this.forceUpdate();
        };
        getMultiLang({ moduleId: "page_table", callback });

        store.setConfig(config);
    }

    componentDidUpdate() {
        if (!this.setScrollFlag) {
            let scrollTopDom = document.querySelector('.u-table-scroll .u-table-body');
            // let scrollTopDom = document.querySelector('.u-table-body');
            if (scrollTopDom) {
                // window.requestAnimationFrame(() => {
                //     scrollTopDom.scrollTop = this.tableScrollTop;
                //     this.setScrollFlag = true;
                // });
                setTimeout(() => {
                    scrollTopDom.scrollTop = this.tableScrollTop;
                    this.setScrollFlag = true;
                }, 200);
            }
        }
    }

    componentDidMount() {
        let { config = {}, store } = this.props;
        if (NODE_ENV === 'development') {
            return
        }
        let data = this.ViewModel.getData(config.dataSource);
        if (data && data.simpleTable && data.simpleTable.pageInfo) {
            return
        }
        // ajax({
        //     url: '/nccloud/platform/templet/pagesize.do',
        //     method: 'post',
        //     data: {},
        //     success: res => {
        //         if (res.data) {
        //             store.setStore(['pageInfo', 'pageSize'], Number(res.data));
        //         }
        //     },
        //     error: err => {
        //         console.log(err)
        //     },
        // });
    }

    componentWillUnmount() {
        let { config = {}, store, name } = this.props;
        let storeData = store.getStore();
        let {
            rows, allpks, pageInfo, checkInfo,
            currentInfo, alltss, props, modelDatas, sortParam, scrollTop,
        } = storeData;
        let scrollTopDom = document.querySelector('.u-table-scroll .u-table-body');
        // let scrollTopDom = document.querySelector('.u-table-body')
        if (scrollTopDom) {
            scrollTop = scrollTopDom.scrollTop
        }
        // 兼容筛选 todo
        if (config.dataSource) {
            let currentpks = [];
            if (config.pkname && Array.isArray(rows) && rows.length) {
                let pkname = config.pkname
                rows.map(item => {
                    if (item) {
                        item.filter = false;
                        delete item.filterIndex;
                    }
                    if (item && item.values && item.values[pkname]) {
                        currentpks.push(item.values[pkname].value);
                    }
                    return item
                });
            }
            //更新allpks
            let pkLen = currentpks.length;
            let { pageIndex, pageSize } = pageInfo;
            let startIndex = (Number(pageIndex) - 1) * Number(pageSize);
            allpks.splice(startIndex, pkLen, ...currentpks);

            let cacheData = {
                allpks,
                currentpks,
                rows,
                pageInfo,
                checkInfo,
                currentInfo,
                alltss,
                props,
                modelDatas,
                sortParam,
                config,
                scrollTop,
            }
            let data = { simpleTable: cacheData };
            //设置缓存数据--清空卡片的缓存数据
            this.ViewModel.setData(config.dataSource, data);
        } else if (allpks) {
            cacheTools.set("allpks", allpks);
        }
    }

    pageInfoChange = newPageInfo => {
        const { store, onPageInfoChange } = this.props;
        const { getStore, setStore } = store;
        let allpks = getStore(['allpks']);
        setStore(['pageInfo'], newPageInfo);
        //存储本次选择的分页条数
        setPageSizeStorage(getStore(['meta']), this.props.name, newPageInfo.pageSize);

        // 如果表格没有数据，则不能使用该方法
        if (allpks.length <= 0) return;
        if (onPageInfoChange) {
            onPageInfoChange(this.splitPks(allpks, newPageInfo.pageIndex, newPageInfo.pageSize), newPageInfo.total);
        }
    };

    _getRowIdbyIndex(indexArr) {
        const { store } = this.props;
        let rows = store.getData();
        let rowIdArr = [];
        rows.forEach((row, index) => {
            if (indexArr.includes(index)) {
                rowIdArr.push(row.rowId);
            }
        });
        return rowIdArr;
    }

    // 分割分页id
    splitPks = (data, pageIndex, pageSize) => {
        if (data.length <= pageSize) {
            // 这里暂时解决问题，后续在进行优化
            return data;
        }
        let pks = [];
        let index = Number(pageIndex);
        let size = Number(pageSize);
        let start = (index - 1) * size;
        for (let i = start; i < start + size; i++) {
            if (data[i]) {
                pks.push(data[i]);
            } else {
                break;
            }
        }
        return pks;
    };

    onAllCheckChange = value => {
        let { store, config = {}, name } = this.props;
        let { getStore, setStore, getData, setSelect, setCrossPage } = store;
        let checkInfo = getStore(['checkInfo']);
        let { selectIndex } = checkInfo;
        let rows = getData()
        // 跨页全选
        if (config.crossPageSelect && config.pkname) {
            setCrossPage(rows, config.pkname, value)
        }

        let oldLen = selectIndex.size;

        if (value) {
            setSelect(undefined, 'all', false);
            setStore(['checkInfo', 'checkedAll'], true, false);
        } else {
            setSelect(undefined, 'clear', false);
            setStore(['checkInfo', 'checkedAll'], false, false);
        }
        let len = rows.length;
        let newLen = getStore(['checkInfo', 'selectIndex']).size;

        this.forceUpdate(() => {
            this.props.onSelectedAll && isFunction(this.props.onSelectedAll) && this.props.onSelectedAll(name, value, len);
            this.props.selectedChange &&
                isFunction(this.props.selectedChange) &&
                this.props.selectedChange(name, newLen, oldLen);
        })
    };

    // 框选等操作
    onBatchSelected(rows = [], hotkey) {
        let { config = {}, store } = this.props;
        let { setSelect, setCrossPage } = store;

        // 清空所有
        setSelect(undefined, 'clear', false);

        rows.forEach(row => {
            let values = JSON.parse(JSON.stringify(row.values || row));
            values.rowId = values.rowId || { value: row.rowId };
            values.selected = values.selected || { value: row.selected };

            let value = [values.rowId.value];
            let selected = values.selected.value;
            // 错误行禁用
            if (values && values.saga_status && values.saga_status.value === '1') {
                return;
            }
            // 跨页选功能
            // 跨页全选
            if (config.crossPageSelect && config.pkname) {
                setCrossPage(values, config.pkname, !selected)
            }
            if (selected && hotkey === 'ctrl') {
                setSelect(value, 'delete', false);
            } else {
                setSelect(value, 'add', false);
            }
        });
        // 更新
        this.forceUpdate && this.forceUpdate();
        config.onBatchSelected && config.onBatchSelected(rows)
    }

    onCheckChange = (record, index, isUnique = false, isDisabledChecked = false) => {
        const { config = {}, name, store } = this.props;
        const { getStore, setSelect, setCrossPage, setTableProps } = store;
        let value = [record.rowId.value];
        let checkInfo = getStore(['checkInfo']);
        let { selectIndex, crossPageSelectIndex } = checkInfo;
        let oldLen = selectIndex.size;
        let selected = record.selected.value
        // 跨页选功能
        // 跨页全选
        if (config.crossPageSelect && config.pkname) {
            if (isUnique) {
                crossPageSelectIndex.clear()
            }
            setCrossPage(record, config.pkname, !selected)
        }

        if (isUnique) {
            if (selected && oldLen === 1) {
                setSelect(value, 'delete', false, isDisabledChecked);
            } else {
                setSelect(value, 'set', false, isDisabledChecked);
            }
        } else {
            if (selected) {
                setSelect(value, 'delete', false, isDisabledChecked);
            } else {
                setSelect(value, 'add', false, isDisabledChecked);
            }
        }

        let newLen = getStore(['checkInfo', 'selectIndex']).size;

        this.forceUpdate(() => { });
        // // 选中选项的回调函数
        this.props.onSelected &&
            isFunction(this.props.onSelected) &&
            this.props.onSelected(name, record, index, !selected);
        this.props.selectedChange &&
            isFunction(this.props.selectedChange) &&
            this.props.selectedChange(name, newLen, oldLen);
    };
    changeCrossSelect = value => {
        const { store, config = {}, name } = this.props;
        const { pkname } = config
        const { getStore, setStore, setSelect, setCrossPage, getData } = store;
        const { checkInfo, allpks } = getStore();
        let { crossPageSelectIndex } = checkInfo;
        let oldLen = crossPageSelectIndex.size
        if (!pkname) { warningOnce(false, `请配置pkname`); return }
        let rows = getData()
        switch (value) {
            case 'current':
                setSelect(undefined, 'all');
                crossPageSelectIndex.clear()
                setCrossPage(rows, pkname, true)
                break;
            case 'all':
                setSelect(undefined, 'allpage');
                crossPageSelectIndex = new Set(allpks)
                break;
            case 'reverse':
                let needSelectRowId = []
                rows.forEach(row => {
                    if (!row.selected) {
                        needSelectRowId.push(row.rowId)
                    }
                })
                setSelect(needSelectRowId, 'set');
                allpks.forEach(pk => {
                    if (crossPageSelectIndex.has(pk)) {
                        crossPageSelectIndex.delete(pk)
                    } else {
                        crossPageSelectIndex.add(pk)
                    }
                })
        }
        setStore(['checkInfo', 'crossPageSelectIndex'], crossPageSelectIndex, false)
        let newLen = crossPageSelectIndex.size
        this.props.selectedChange &&
            isFunction(this.props.selectedChange) &&
            this.props.selectedChange(name, newLen, oldLen);
    };
    sortChange = (sortParam, sortData) => {
        let sortObj = {};
        let sortRows = [];
        let { store, name } = this.props;
        let { setStore, getData, getMeta } = store;
        let rows = getData();
        /**
         * 每次排序都存储新的排序信息
         * 这是产品李聪慧让加的, 为了解决单据打印时，列表对中字段排序产生的问题
         */
        setStore(['sortParam'], {
            mode: "single",
            sortParam,
        }, false);
        sortParam.forEach(item => {
            sortObj[item.field] = item;
        });
        // 处理排序多表头
        handleSortcolumns({
            columns: getMeta(name) || [],
            sortObj,
        });
        sortData.forEach(data => {
            rows.forEach(row => {
                if (data.rowId.value === row.rowId) {
                    sortRows.push(row);
                }
            });
        });
        store.setData(sortRows, true);
    }
    checkNum = () => {
        let { config = {}, store } = this.props;
        let num = 0;
        if (config.crossPageSelect && config.pkname) {
            num = store.getStore(['checkInfo', 'crossPageSelectIndex']).size;
        } else {
            num = store.getStore(['checkInfo', 'selectIndex']).size;
        }
        return num;
    }

    updateColumnAfterSetting = (newColumn, areaCode) => {
        let { store } = this.props;
        let { getStore, setMeta } = store;
        let meta = getStore(['meta']);
        if (newColumn.length && areaCode) {
            let finalColumn = [...meta[areaCode].items];

            let sortIndexArr = [];
            let sortObjArr = [];
            newColumn.map(newItem => {
                meta[areaCode].items.map((oldItem, index) => {
                    if (newItem.attrcode === oldItem.attrcode) {
                        oldItem.width = newItem.width;
                        oldItem.islock = newItem.islock;
                        oldItem.visible = newItem.visible;
                        sortIndexArr.push(index);
                        sortObjArr.push(oldItem);
                    }
                });
            });
            sortIndexArr.sort((a, b) => a - b);
            sortIndexArr.forEach((item, index) => {
                finalColumn[item] = sortObjArr[index];
            });
            meta[areaCode].items = finalColumn;
            //this.key = this.key + 1;
            setMeta(meta);
            //this.setState({ key: this.state.key + 1 })
        }
    }
    getColSettiongParam = (meta, name) => {
        if (meta && meta[name]) {
            let currentMeta = meta[name]
            let config = this.props.store.getConfig()
            return {
                appcode: currentMeta.appcode || pageTo.getAppCode(),
                pagecode: currentMeta.pagecode || pageTo.getPageCode(),
                code: currentMeta.templateCode || meta.code,
                pageid: currentMeta.pageid || meta.pageid,
                areaCode: name,
                areaId: currentMeta.oid,
                noColSetClass: config.noColSetClass,
                colSetCallback: config.colSetCallback,
            }
        } else {
            return {}
        }
    }

    //右键菜单点击函数
    contextMenuClick = (btnCode, execCommandCopy, param, insertNum, rowIndex, isHotKey = false) => {
        let { store, name } = this.props;
        let { getCache, getRows, getMeta, getArrayMeta, getData, getFilterData } = store;
        let copyCode = ["copy", "copy-row", "copy-column"]
        if (copyCode.includes(btnCode)) {
            //选中表头
            let activeHeads = getCache('activeSpecialCells');
            console.log("表头选中信息：", activeHeads)
            let arrayMeta = getArrayMeta(name);
            let currentMeta = store.getMeta(name)

            let activedCells = getCache("activedCells");

            if (isHotKey && !activedCells && !activeHeads) {
                return;
            }

            let selectedRows = [];
            if (btnCode === 'copy' || btnCode === 'copy-row') {
                selectedRows = getRows({ rowKeyValues: Object.keys(activedCells) });
            } else if (btnCode === 'copy-column') {
                selectedRows = getFilterData();
            }
            isFunction(execCommandCopy) && execCommandCopy(activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
        }
    };

    render() {
        const { store, name, config = {}, onCellHyperlinkTo, rowClassName } = this.props;
        const { getStore, setStore, getData, getFilterData, getMeta, setTableProps, getTableProps, setClickedRow, getArrayMeta, setSelect, hasSelected, setCellProps, setCellValue } = store;
        const { pageInfo, checkInfo } = getStore();
        let {
            foldCacheId, //对于没有appcode，pagecode..的表格，做折行缓存的标志
            cancelCustomRightMenu = false, addBlankCol, extendRightMenu, checkColArea,
            showWidthAndHeightConfig = true, height, high_selectable = {}, hideRightMenu = false, high_filter = {}, defaultPageSize, ...others

        } = config
        if (hideRightMenu) {
            cancelCustomRightMenu = true;
            high_selectable.enable = false;
            showWidthAndHeightConfig = false;
        }
        let items = getMeta(name) || [];
        // 要注意下卡片返回列表时的定位问题, 除此之外，currentIndex和focusIndex 还要注意 过滤的情况
        // let focusIndex = getTableProps('focusIndex');
        let meta = getStore(['meta']);
        let showPagination;
        if (meta && meta[name]) {
            showPagination = meta[name].pagination;
            if (!pageInfo.pageSize) {
                let prePageSize = getPageSizeStorage(meta, name, defaultPageSize);
                pageInfo.pageSize = prePageSize;
                setStore(['pageInfo'], pageInfo, false)
            }
        }
        let colSettingParam = this.getColSettiongParam(meta, name)
        const otherAreaHeight = isNumber(config.otherAreaHeight) && !Number.isNaN(config.otherAreaHeight) ? config.otherAreaHeight : 0;
        let adaptionHeight = !!(isUndefined(config.height) && (config.adaptionHeight || isUndefined(config.adaptionHeight)))
        let heightObj = {}
        if (height) {
            heightObj.scroll = { y: height }
            heightObj.bodyStyle = { height: height }
        } else {
            heightObj.scroll = { y: 300 }
            heightObj.bodyStyle = { height: 300 }
        }
        let data = getFilterData();
        //兼容旧版本
        let rows = data.map(row => {
            let values = row.values;
            ['status', 'rowId', 'selected', 'isOptimized', 'disabled'].forEach(key => {
                if (!isObject(row[key])) {
                    values[key] = { value: row[key] }
                }
            })
            delete values.values;
            return values;
        });
        // arrcolumns
        let arrColumns = getArrayMeta(name);
        // rowKey
        let rowKey = 'rowId';
        let isTotal = config.showTotal || getMetaIsTotal(items);

        // 默认开启 框选 high_selectable.enable
        //high_selectable.enable = high_selectable.enable || true;

        // 默认启用快捷键选中 只有快捷键选中行
        if (isUndefined(high_selectable.enable)) {
            high_selectable.enable = true;
            // high_selectable.disabled = isUndefined(high_selectable.disabled) ? 'slide' :
            //     (isArray(high_selectable.disabled) ? high_selectable.disabled : [high_selectable.disabled]);
            // high_selectable.single = high_selectable.single || false;
            // // 不开启
            // high_selectable.selectCell = isUndefined(high_selectable.selectCell) ? false : high_selectable.selectCell;
        }
        // console.log(high_selectable);
        // 是否框选(批选)
        const isBatchSelect = isUndefined(high_selectable.selectCell) ? true : high_selectable.selectCell;

        // 放在上面，避免每次都执行，影响效率
        let tableRows = getData();

        // 处理下过滤的情况 currentIndex默认存为过滤之前的index
        let currentIndex = getTableProps('currentIndex');
        let currentNormalIndex = currentIndex !== -1 ? store.getTrueRowIndex({ rowIndex: currentIndex }, 'filter', 'normal') : -1;

        return (
            <React.Fragment>
                {items.length ? < MetaTable
                    {...this.props}
                    columns={items}
                    data={rows}
                    className={` simpleTable  ${this.uuid} `}//用于识别table
                    rowKey={rowKey}
                    status={'browse'}
                    disableEditType
                    // height={this.state.isEllipsis ? 30 : undefined}
                    {...heightObj}
                    adaptionHeight={adaptionHeight}
                    cardType="simple"
                    otherAreaHeight={showPagination ? 42 + otherAreaHeight : otherAreaHeight}
                    renderStatus="browse"
                    bodyDisplayInRow={isUndefined(config.multipleRowCell) ? this.state.isEllipsis : !config.multipleRowCell}
                    bodyExpandAllInRow={config.bodyExpandAllInRow}
                    pageInfo={pageInfo}
                    pageInfoChange={this.pageInfoChange}
                    isDrag={config.isDrag}
                    // config.lazyload
                    lazyload={config.lazyload}
                    langJson={store.getCache('langJson')} // 给单元格用
                    getTrueRowIndex={(rowKeyValue, index, record) => {
                        index = store.getTrueRowIndex({ rowKeyValue }, 'normal', 'filter');
                        return index;
                    }}
                    // autoAffixScroll
                    getCellValue={param => {
                        let { rowKeyValue, attrcode } = param;
                        let rows = tableRows;
                        let row = rows.query && rows.query(rowKeyValue.value)
                        return ((row || {})["values"] || {})[attrcode] || {};
                    }}
                    setCellValue={params => {
                        let { rowKeyValue, attrcode, value = {} } = params;
                        setCellValue({ rowId: rowKeyValue, attrcode, value }); // 这里就是onChange 和 onBlur 的集合
                    }}
                    onCellHyperlinkTo={(record, attrcode, value) => {
                        onCellHyperlinkTo && onCellHyperlinkTo(record, attrcode, value, name);
                    }}
                    cellClassName={({ rowIndex: index, record, attrcode: code }) => {
                        return isFunction(config.setCellClass) && config.setCellClass(index, record, code);
                    }}
                    setSelectedIndex={({ record, rowKey, rowIndex }, e) => {
                    }}
                    disableClickRowToSelectIndex={config.disableClickRowToSelect}
                    // focusIndex={focusIndex} //用于聚焦
                    onRowClick={(record, index, e) => {
                        if (record.disabled && record.disabled.value === true) return;
                        // 为了避免和新开发的行框选点选区域选事件冲突
                        if (high_selectable && high_selectable.enable && (e.ctrlKey || e.shiftKey || e.metaKey)) {
                            return;
                        }
                        //更新当前选中行
                        setTableProps('currentIndex', store.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter'), false);

                        // 错误行禁用
                        let values = record.values || record;
                        if (
                            !values ||
                            !values.saga_status ||
                            values.saga_status.value !== "1"
                        ) {
                            setClickedRow(index, false);
                            if (e.target.localName !== 'a' && e.target.className !== 'code-detail-link' && !config.disableClickRowToSelect) {//点击a标签不候选行
                                this.onCheckChange(record, index, true, config.disableClickRowToCheckbox);
                            }
                        }

                        this.props.onRowClick &&
                            isFunction(this.props.onRowClick) &&
                            this.props.onRowClick(record, index, e);
                    }}
                    // 选中行
                    hasSelected={({ rowKey, rowKeyValue, rowIndex }) => {
                        return hasSelected(['checkInfo', 'selectIndex'], rowKeyValue);
                    }}
                    onRowDoubleClick={(record, index, e) => {
                        //------------双击checkbox区域，不跳转 start------
                        let className = e.target.className
                        let type = e.target.type
                        if (typeof className === 'string' && className.indexOf('checkbox') != -1) {
                            return
                        } else if (typeof className === 'string' && className.indexOf('cell-content') != -1) {
                            let parentNodeClass = e.target.parentElement.parentElement.className
                            if (typeof parentNodeClass === 'string' && parentNodeClass.indexOf('checkbox') != -1) {
                                return
                            }
                        } else if (type === 'checkbox') {
                            return
                        }
                        //------------双击checkbox区域，不跳转 end------
                        //更新当前选中行
                        setTableProps('currentIndex', store.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter'), false);

                        // 错误行禁用
                        let values = record.values || record;
                        if (
                            !values ||
                            !values.saga_status ||
                            values.saga_status.value !== "1"
                        ) {
                            setClickedRow(index);
                        }

                        this.props.onRowDoubleClick &&
                            isFunction(this.props.onRowDoubleClick) &&
                            this.props.onRowDoubleClick(record, index, e);
                    }}
                    name={name}
                    store={store}
                    onAllCheckChange={value => this.onAllCheckChange(value)}
                    onCheckChange={(record, index, e) => {
                        // 为了避免和新开发的行框选点选区域选事件冲突
                        if (high_selectable && high_selectable.enable &&
                            (this.hotkey == 'shift' || this.hotkey == 'ctrl' || this.hotkey == 'ctrl&shift')) {
                            this.hotkey = null;
                            return;
                        }
                        this.hotkey = null;
                        this.onCheckChange(record, index)
                    }}
                    checkInfo={checkInfo}
                    backSort={config.sort}
                    sortChange={this.sortChange} //排序的回调
                    checkNum={this.checkNum}//显示已选行数
                    showPagination={showPagination}
                    rowClassName={(record, current) => {
                        if (isFunction(rowClassName)) {
                            current = store.getTrueRowIndex({ rowIndex: current }, 'normal', 'filter');
                        }

                        let cls = '';
                        let focusInd = currentNormalIndex;
                        if (current === focusInd) {
                            cls = 'focusrow '
                        }
                        cls += isFunction(rowClassName) ? rowClassName(record, current) : rowClassName;
                        return cls;
                    }}
                    checkColConfig={{
                        showCheck: config.showCheck || false,
                        changeCrossSelect: this.changeCrossSelect,
                        crossPageSelect: config.crossPageSelect,
                        langJson: store.getCache('langJson'),
                        checkColArea: isFunction(checkColArea) ? (text, record, index) => {
                            index = store.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter');
                            return checkColArea(text, record, index);
                        } : undefined,
                    }}
                    indexColConfig={{
                        showIndex: config.showIndex || false,
                        showCheck: config.showCheck || false,
                        langJson: store.getCache('langJson'),
                    }}
                    totalConfig={{
                        showTotal: config.showTotal,
                        noTotalRows: config.noTotalRows,
                        noTotalCol: config.noTotalCol,
                        allowTotalRows: config.allowTotalRows,
                        numberPd: 7,
                    }}
                    isTotal={isTotal}
                    high_filter={{
                        alloweFilter: true,
                        langJson: store.getCache('langJson'),
                        panelTitle: store.getStore(['meta', name, 'name']) || '',
                        filterData: store.getData(),
                        customColData: store.getCache('customColData'),
                        rowKey,
                        isReset: store.getStore('filterReset'),
                        arrColumns,
                        handleFilterCallBack: (params = {}) => {
                            let {
                                filteredRow,
                                filteredRowKeyValues,
                                columnStatus = {},
                            } = params;

                            // 更新重置状态
                            store.setStore('filterReset', false, false);

                            // 设置列状态  设置表格筛选图标
                            store.setColumn(name, columnStatus, false);

                            // 设置行状态
                            let data = store.getData() || [];
                            data.forEach(row => {
                                if (filteredRowKeyValues.includes(row[rowKey])) {
                                    row.filter = false;
                                } else {
                                    row.filter = true;
                                }
                            });

                            // 处理已选等逻辑
                            let checkInfo = store.getStore('checkInfo');
                            if (filteredRow && filteredRow.length) {
                                checkInfo.indeterminate = filteredRow.some(item => {
                                    let values = item.values || item;
                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                    return unSaga && item.selected;
                                });
                                checkInfo.checkedAll = filteredRow.every(item => {
                                    let values = item.values || item;
                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                    return !unSaga || item.selected;
                                });
                            }
                            store.setStore(["checkInfo"], checkInfo, false);

                            // 更新 所有的更新放到弹窗关闭(onHide)时
                            store.setData({ data, shouldForceUpdate: false });
                            config.handleFilterCallBack && config.handleFilterCallBack();
                        },
                        handleResetCallBack: params => {
                            let { columnStatus } = params;
                            // 更新重置状态
                            store.setStore('filterReset', false, false);
                            // 设置行状态
                            let data = store.getData() || [];
                            data.forEach(row => {
                                row.filter = false;
                                delete row.filterIndex;
                            });

                            // 处理已选等逻辑
                            let checkInfo = store.getStore('checkInfo');
                            let viewData = data;
                            if (viewData && viewData.length) {
                                checkInfo.indeterminate = viewData.some(item => {
                                    let values = item.values || item;
                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                    return unSaga && item.selected;
                                });
                                checkInfo.checkedAll = viewData.every(item => {
                                    let values = item.values || item;
                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                    return !unSaga || item.selected;
                                });
                            }
                            store.setStore(["checkInfo"], checkInfo, false);

                            // 更新 所有的更新放到弹窗关闭(onHide)时
                            store.setData({ data, shouldForceUpdate: false });

                            // 列状态
                            store.setColumn(name, columnStatus, false);
                            config.handleResetCallBack && config.handleResetCallBack();
                        },
                        onHide: params => {
                            let { columnStatus } = params;
                            store.setColumn(name, columnStatus, true);
                        },
                        ...(high_filter || {}),
                    }}
                    high_selectable={{
                        store,
                        onSelect: (activedCells = {}, activeSpecialCells = [], hotkey, { current, isCheckbox, isBatch }) => {
                            // console.log(activedCells, hotkey, current);
                            let pervActivedCells = store.getCache('activedCells') || {};

                            // TODO Saga相关的内容
                            for (let rowKeyValue in activedCells) {
                                pervActivedCells[rowKeyValue] = pervActivedCells[rowKeyValue] || [];
                                for (let attrcode of activedCells[rowKeyValue]) {
                                    pervActivedCells[rowKeyValue].push(attrcode);
                                    // 解决批改时边线样式不对的问题
                                    if (isBatchSelect) {
                                        setCellProps(rowKeyValue, attrcode, {
                                            actived: true,
                                            leftLine: false,
                                            rightLine: false,
                                            topLine: false,
                                            bottomLine: false,
                                        }, false);
                                        let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                        cellObj && cellObj.setState({});
                                    }
                                }
                            }

                            // 重新赋值
                            activedCells = pervActivedCells;

                            // 批操作
                            if (isBatch) {
                                this.hotkey = hotkey;
                                let rows = [];
                                for (let rowKeyValue in activedCells) {
                                    // TODO
                                    let data = getData();
                                    if (data.length && isFunction(data.query) && (activedCells[rowKeyValue].length > 0 || isCheckbox)) {
                                        rows.push(data.query(rowKeyValue));
                                    }
                                }
                                if (config.onBatchSelected) {
                                    this.onBatchSelected(rows, hotkey)
                                } else {
                                    // let selectIndex = store.getStore(['checkInfo', 'selectIndex']);
                                    // selectIndex.clear();
                                    // setSelect([], 'clear', false);
                                    rows.forEach(row => {
                                        let rowKeyValue = row[rowKey];
                                        let values = row.values || row,
                                            isSagaStatus = values && values.saga_status && values.saga_status.value === '1';
                                        // 错误行禁用
                                        if (isSagaStatus && row.disabled) {
                                            return;
                                        }
                                        // !selectIndex.has(rowKeyValue) && selectIndex.add(rowKeyValue);
                                        let isSelected = hasSelected(['checkInfo', 'selectIndex'], rowKeyValue);
                                        // !isSelected && setSelect([{ rowKey, rowKeyValue, pattern: 'add' }], 'add', false);
                                        !isSelected && setSelect(rowKeyValue, 'add', false, true);
                                    });
                                }
                            }

                            if (hotkey === 'down' &&
                                current &&
                                current.rowKeyValue) {
                                setCellProps(current.rowKeyValue, current.attrcode, { showDrag: true }, false);
                                let cellObj = store.getCache(['cell', current.rowKeyValue + current.attrcode]);
                                cellObj && cellObj.setState({});
                            }

                            // 缓存已选
                            store.setCache('activedCells', activedCells);
                            store.setCache('activedCell', current);

                            // 没有选中单元格
                            if (!isBatchSelect) {
                                this.forceUpdate();
                                return;
                            }
                            // 选中表头
                            let oldActiveSpecialCells = store.getCache('activeSpecialCells') || [];
                            if (activeSpecialCells.length > 0 || (activeSpecialCells.length === 0 && oldActiveSpecialCells.length !== 0)) {
                                store.setCache('activeSpecialCells', activeSpecialCells);
                                // console.log(store.getCache('activeSpecialCells'), 'activeSpecialCellsactiveSpecialCellsactiveSpecialCellsactiveSpecialCells');

                                oldActiveSpecialCells = oldActiveSpecialCells.map(item => item.attrcode);
                                activeSpecialCells = activeSpecialCells.map(item => item.attrcode);

                                // 不能用actived 会有冲突
                                store.setColumn(name, oldActiveSpecialCells, { unitActive: false }, false);
                                // 不能用actived 会有冲突
                                store.setColumn(name, activeSpecialCells, { unitActive: true }, false);
                            }

                            // 修正边框
                            if (activedCells || activeSpecialCells) {
                                let arrayMeta = store.getArrayMeta(name, true),
                                    arrayMetaLen = arrayMeta.length;
                                let viewData = store.getFilterData();
                                let linkObjMeta = arrayMeta.reduce((result, item, i, arr) => {
                                    let next = arr[i + 1] || {};
                                    let prev = arr[i - 1] || {};
                                    if (item.visible) {
                                        result[item.attrcode] = {
                                            prev: prev.attrcode,
                                            current: item.attrcode,
                                            next: next.attrcode,
                                        }
                                    }
                                    return result;
                                }, {});

                                viewData.forEach((row, i) => {
                                    let values = row.values;
                                    let prevRowValues = (viewData[i - 1] || {}).values || {};
                                    let nextRowValues = (viewData[i + 1] || {}).values || {};
                                    let rowKeyValue = row[rowKey];

                                    for (let attrcode in values) {
                                        let cell = values[attrcode];
                                        if (cell['actived']) {
                                            let prevCol = linkObjMeta[attrcode] && linkObjMeta[attrcode].prev;
                                            let nextCol = linkObjMeta[attrcode] && linkObjMeta[attrcode].next;

                                            cell['leftLine'] = !prevCol || !values[prevCol] || !values[prevCol]['actived'];

                                            cell['rightLine'] = !nextCol || !values[nextCol] || !values[nextCol]['actived'];

                                            cell['topLine'] = prevRowValues && (!prevRowValues[attrcode] || !prevRowValues[attrcode]['actived']);

                                            cell['bottomLine'] = nextRowValues && (!nextRowValues[attrcode] || !nextRowValues[attrcode]['actived']);

                                            // 单体更新
                                            let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                            cellObj && cellObj.setState({});
                                        }
                                    }
                                });

                                // console.log(linkObjMeta, viewData);
                            }

                            // 更新表格
                            if (hotkey !== 'down' && hotkey !== 'drag') {
                                this.forceUpdate();
                            }
                        },
                        getPrevSelector: key => {
                            const activedCells = store.getCache('activedCells') || {},
                                prevSelector = {};
                            for (let rowKeyValue in activedCells) {
                                (activedCells[rowKeyValue] || []).forEach(attrcode => {
                                    prevSelector[`${rowKeyValue}@${attrcode}`] = true;
                                });
                            }
                            // console.log(activedCells, prevSelector, 'prevSelector');
                            return prevSelector;
                        },
                        onDeSelect: (deActiveCells, hotkey) => {
                            let prevActiveCells = store.getCache('activedCells') || {};
                            let prevActiveCellsObj = {};
                            let prevActiveCellsArr = {};
                            // 转对象
                            for (let rowKeyValue in prevActiveCells) {
                                prevActiveCellsObj[rowKeyValue] = {};
                                for (let attrcode of prevActiveCells[rowKeyValue]) {
                                    prevActiveCellsObj[rowKeyValue][attrcode] = true;
                                }
                            }

                            // 清理数据
                            for (let rowKeyValue in deActiveCells) {
                                for (let attrcode of deActiveCells[rowKeyValue]) {
                                    if (prevActiveCellsObj[rowKeyValue][attrcode]) {
                                        delete prevActiveCellsObj[rowKeyValue][attrcode];
                                        // setCellProps(rowKeyValue, attrcode, { showDrag: false }, false);
                                        setCellProps(rowKeyValue, attrcode, {
                                            actived: false,
                                            leftLine: false,
                                            rightLine: false,
                                            topLine: false,
                                            bottomLine: false,
                                        }, false);
                                        let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                        cellObj && cellObj.setState({});
                                    }
                                }
                            }

                            // 转数组
                            for (let rowKeyValue in prevActiveCellsObj) {
                                prevActiveCellsArr[rowKeyValue] = [];
                                for (let attrcode in prevActiveCellsObj[rowKeyValue]) {
                                    prevActiveCellsArr[rowKeyValue].push(attrcode);
                                }
                            }

                            // 重新赋值
                            store.setCache('activedCells', prevActiveCellsArr);
                        },
                        onClearData: (shouldForceUpdate = false, hotkey) => {
                            store.setCache('activedCells', {});
                            store.setCache('activeSpecialCells', []);
                            // 没有选中单元格
                            if (!isBatchSelect) {
                                return;
                            }
                            // let activedCells = store.getCache('activedCells') || {};
                            // let activedCell = store.getCache('activedCell');
                            // 全过滤一下
                            let data = store.getData() || [];
                            data.forEach(row => {
                                let values = row.values || row || {};
                                let rowKeyValue = row.rowId || values.rowId.value;
                                let showDrag = false;
                                let actived = false;
                                for (let attrcode in values) {
                                    showDrag = values[attrcode] && values[attrcode].showDrag;
                                    showDrag && setCellProps(rowKeyValue, attrcode, { showDrag: false }, false);
                                    actived = values[attrcode] && values[attrcode].actived;
                                    actived &&
                                        setCellProps(rowKeyValue, attrcode, {
                                            actived: false,
                                            leftLine: false,
                                            rightLine: false,
                                            topLine: false,
                                            bottomLine: false,
                                        }, false);
                                    if (showDrag || actived) {
                                        let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                        cellObj && cellObj.setState({});
                                    }
                                }
                            });

                            const columns = store.getArrayMeta(name, false) || [];
                            shouldForceUpdate = false;
                            columns.forEach(item => {
                                shouldForceUpdate = shouldForceUpdate || item.unitActive;
                                item.unitActive && store.setColumn(name, [item.attrcode], { unitActive: false }, false);
                            });
                            // 暂时先这样判断, 表头被选中时再全局更新
                            if (shouldForceUpdate) {
                                this.forceUpdate();
                            }
                        },
                        disabled: 'drag',
                        arrColumns: getArrayMeta(name, false),
                        data,
                        rowKey,
                        onBatchChange: (activeCell = {}, activeCells = {}) => {
                            // 没有选中单元格
                            if (!isBatchSelect) {
                                return;
                            }
                            // activeCell = {}, activeCells = {}
                            // 开始  批改以下所有
                            activeCell = {
                                ...activeCell,
                                rowIndex: store.getTrueRowIndex({ rowKeyValue: activeCell.rowKeyValue }),
                                attrcode: activeCell.attrcode,
                                column: store.getColumn(name, activeCell.attrcode) || {},
                            };

                            // 表格数据  是否包含过滤之后的数据 这里要斟酌下
                            let rows = store.getData();
                            let viewData = store.getFilterData();

                            let pasteContent = [];

                            // 取值 getCellValue({ rowKeyValue, attrcode })
                            let activeCellValue = {};
                            if (rows.length && isFunction(rows.query)) {
                                activeCellValue = rows.query(activeCell.rowKeyValue).values[activeCell.attrcode] || {};
                            }

                            for (let rowKeyValue in activeCells) {
                                let attrcode = activeCells[rowKeyValue][0];
                                // 做过一次radio和checkkbox转换为select 这里可能会有问题 TODO测试
                                let isDisplay = (activeCell.column.itemtype === 'refer' && activeCell.column.fieldDisplayed === 'refname') || activeCell.column.itemtype === 'select';
                                attrcode && pasteContent.push(isDisplay ? activeCellValue.display : activeCellValue.value);
                            }

                            console.log('onBatchChange trigger', activeCell, activeCells);

                            let param = {
                                pasteContent,
                                activeCell,
                                // TODO 没有默认加载多语 需要加载多语之后传入
                                langJson: store.getCache('langJson') || {},
                                rows,
                                viewData,
                                getRowOldValue: store.getRowOldValue,
                                name,
                                store: store,
                            }
                            let data = excelPackageData(param);
                            config.onBatchChange && config.onBatchChange(data);
                        },
                        isBatchSelect,
                        ...high_selectable,
                        single: false,
                    }}
                    //折行回调
                    foldingRowChange={isEllipsis => {
                        this.setState({ isEllipsis })
                    }}
                    foldCacheId={foldCacheId}
                    //右键菜单配置参数
                    contextMenuConfig={{
                        enable: high_selectable && high_selectable.enable && isBatchSelect,
                        status: "browse",
                        contextMenuClick: this.contextMenuClick,
                        showBusinessBtn: false,
                        showPasteBtn: false,
                        langJson: store.getCache('langJson') || {},
                        extendRightMenu: extendRightMenu,
                    }}
                    //滚动条悬浮
                    autoAffixScroll
                    //——————————操作列————————————
                    colsSettingParam={colSettingParam}
                    cancelCustomRightMenu={cancelCustomRightMenu}
                    showWidthAndHeightConfig={showWidthAndHeightConfig}
                    updateColumnHandle={this.updateColumnAfterSetting}
                    //key={this.key}
                    {...others}
                    //——————————操作列end————————————
                    //空白列
                    addBlankCol={addBlankCol}
                    modalConfig={{ langJson: store.getCache('langJson') }}
                /> : null}
                {isFunction(config.tableCustomerArea) && config.tableCustomerArea()}
            </React.Fragment>
        );
    }
}

// SimpleTable.createStroe = createStore;

// export default SimpleTable

SimpleTable.displayName = 'SimpleTable';
SimpleTable.useSimpleTable = useSimpleTable;

export default SimpleTable;
