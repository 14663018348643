(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@platform/api/index"), require("@platform/base/index"), require("@platform/table-core/index"), require("@platform/template/index"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@platform/api/index", "@platform/base/index", "@platform/table-core/index", "@platform/template/index"], factory);
	else if(typeof exports === 'object')
		exports["@platform/simple-table/index"] = factory(require("react"), require("@platform/api/index"), require("@platform/base/index"), require("@platform/table-core/index"), require("@platform/template/index"));
	else
		root["@platform/simple-table/index"] = factory(root["React"], root["@platform/api/index"], root["@platform/base/index"], root["@platform/table-core/index"], root["@platform/template/index"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__13__) {
return 