//1排序 2筛选 3checkbox
import { BaseStore, hookFactory, ArrayData, TreeData } from '@platform/template';
import { getSafeRandom } from '@platform/api'
import { utils } from '@platform/table-core';
const { isArray, isString, isObject, isUndefined, isNullOrVoid, getPageSizeStorage } = utils;
// 表格数据
export function TableData(data, keyPath = 'rowid') {
    return new ArrayData(data, keyPath);
}
// 表格模板
export function TableMeta(data, keyPath = 'attrcode') {
    for (let areacode of Object.keys(data)) {
        if (isObject(data[areacode]) &&
            isArray(data[areacode].items) &&
            data[areacode].moduletype === 'table') {
            data[areacode].items = new TreeData(data[areacode].items, keyPath);
        }
    }
    return data;
}

class simpleTableStore extends BaseStore {
    store = {
        meta: {}, // 表格模板   
        // store.rows = []// 数据
        rows: [], // 数据
        // 表格属性
        props: {
            status: 'browse',
            focusIndex: '-1', // （用于跳转）
            rowKey: 'rowId',
            // 当前正在操作的行
            currentIndex: -1,
            currentInfo: null, // （选中行）
        },
        pageInfo: { // 分页信息
            //pageSize: 0,
            pageIndex: 1,
            total: 0,
            totalPage: 1,
        },
        allpks: [],
        alltss: [],

        checkInfo: {
            checkedAll: false, //当前页全选标志
            indeterminate: false, //半选标志
            selectIndex: new Set(), //选中行
            disabledAll: false,
            checkedAllpage: false, //所有页全选
            crossPageSelectIndex: new Set(),
        },
        modelDatas: { //编辑模态框的相关数据
            modelColumn: [],
            record: {},
            index: null,
            rowId: null,
            operType: 'add', // 编辑框类型‘add’或者‘edit’
            showModal: false, //是否打开编辑框
        },
        sortParam: undefined, //存储新的排序信息
        config: {}, //业务组传进来的config
    }
    // 缓存
    cache = {
        base: null, // 不同情况的初始数据
        beforeEdit: null, //侧拉展开之前的数据（）
        all: null, //初始表格的全数据
        // 进行深度删除后的数据，作为删除后的操作的初始数据 为了在浏览态删除后，编辑态取消不会将删除的数据在显示出来
        afterDel: null,
        // 行的旧值 为数组 既可以存对象 也可以存索引
        oldRows: [],
        // 列初始值
        colInitialValue: {},
        // 多语
        langJson: {},
        // 批改
        oldBatchData: null,
    };

    getHooks = () => {
        return {
            getStore: this.getStore,
            setStore: this.setStore,
            setData: this.setData,
            refresh: this.refresh,
            getData: this.getData,
            getFilterData: this.getFilterData,
            getRows: this.getRows,
            setRows: this.setRows,
            setMeta: this.setMeta,
            getMeta: this.getMeta,
            getArrayMeta: this.getArrayMeta,
            setCellProps: this.setCellProps,
            setColumn: this.setColumn,
            setSelect: this.setSelect,
            openModal: this.openModal,
            closeModal: this.closeModal,
            setTableProps: this.setTableProps,
            getTableProps: this.getTableProps,
            showColumn: this.showColumn,
            deleteTableRows: this.deleteTableRows,
            addTableRows: this.addTableRows,
            setCellValue: this.setCellValue,
            setCache: this.setCache,
            getCellProps: this.getCellProps,
            getCache: this.getCache,
            clearSortStatus: this.clearSortStatus,
            resetFilter: this.resetFilter,
            hasSelected: this.hasSelected,
            // 内部使用方法
            getClickedRow: this.getClickedRow,
            setClickedRow: this.setClickedRow,
            setModalValue: this.setModalValue,
            getConfig: this.getConfig,
            setConfig: this.setConfig,
            setCrossPage: this.setCrossPage,
            getTrueRowIndex: this.getTrueRowIndex,
        }
    }

    /**
     * TODO 看看能不能优化下
     * 获取真实的rowIndex  不建议频繁使用
     * view 目前有 normal sort delete filter
     * @description: 获取行的index
     * @param {type}
     * @return:
     */
    getTrueRowIndex = ({ rowKeyValue, rowIndex } = {}, view = "normal", outputView = 'filter') => {
        if (isUndefined(rowKeyValue) && isUndefined(rowIndex)) {
            console.warn("invalid params");
            return -1;
        }

        let rowKey = this.getTableProps("rowKey");
        let filterData = this.getFilterData();
        let allData = this.getData();
        // 内外之分
        let data = outputView === 'normal' ? filterData : allData;
        let len = data.length;

        // 只传index 的情况
        if (isUndefined(rowKeyValue)) {
            let row = (view === 'normal' ? filterData : allData)[rowIndex];
            if (!row) {
                console.warn("not find row, maybe row overflow");
                return -1;
            }
            rowKeyValue = row[rowKey];
        }

        // 可以some 优化下
        data.some((item, index) => {
            if (item[rowKey] === rowKeyValue) {
                rowIndex = index;
            }
            return item[rowKey] === rowKeyValue;
        });

        rowIndex = rowIndex >= 0 && rowIndex < len ? rowIndex : -1;
        rowIndex === -1 && console.warn("rowIndex 为 -1，可能会导致后面报错");
        return rowIndex;
    }

    /**
     * 刷新表格
     */
    refresh = (callback) => {
        this.forceUpdate(callback);
    }

    /**
     * rowKeyValue
     * @description: 是否选中行
     * @param {type}
     * @return:
     */
    hasSelected = (paths, rowKeyValue = '') => {
        let selectedIndex = this.getStore(paths);
        return selectedIndex.has(rowKeyValue.value || rowKeyValue);
    }

    /**
     * 重置过滤器
     */
    resetFilter = (tableId, shouldForceUpdate = true) => {
        tableId = tableId || this.getStore("name");
        if (!tableId) {
            return console.warn('invalid resetFilter tableId note find');
        }
        let arrColumns = this.getArrayMeta(tableId, true);

        let columnStatus = [];
        // 设置表格筛选图标, 重置时将筛选图标全部清空
        arrColumns.forEach(column => {
            columnStatus.push(column.attrcode);
        });

        // 设置行状态
        let data = this.getData() || [];
        data.forEach(row => {
            row.filter = false;
            delete row.filterIndex;
        });

        // 处理已选等逻辑
        let checkInfo = this.getStore('checkInfo');
        let viewData = data;
        if (viewData && viewData.length) {
            checkInfo.indeterminate = viewData.some(item => {
                let values = item.values || item;
                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                return unSaga && item.selected;
            });
            checkInfo.checkedAll = viewData.every(item => {
                let values = item.values || item;
                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                return !unSaga || item.selected;
            });
        }
        this.setStore(["checkInfo"], checkInfo, false);

        // 更新 所有的更新放到弹窗关闭(onHide)时
        this.setData(data, false);

        // 列状态
        this.setColumn(tableId, columnStatus, { filterStatus: '' }, shouldForceUpdate);
    }

    /**
     * rowIndex 可视数据index
     * @description: 设置单元格属性
     * @param {type} 
     * @return: 
     */
    setCellProps = (rowKeyValue, attrcode, value = {}, shouldForceUpdate = true, pattern = 'merge') => {
        if (isUndefined(rowKeyValue) || rowKeyValue === -1) {
            console.log('invalid params');
            return;
        }

        // get(this.valueGraph, [rowKeyValue, attrcode]).forceUpdate();
        let data = this.getData()
        let row = data.update(rowKeyValue, oldRow => {
            let props = (oldRow['values'] || {})[attrcode] || {};
            if (pattern == 'merge') {
                value = { ...props, ...value };
            }
            oldRow["values"] = oldRow["values"] || {};
            oldRow["values"][attrcode] = value
            return oldRow
        });

        shouldForceUpdate && this.forceUpdate();
        // 这里需要优化
        // shouldForceUpdate && this.updateItem([rowKeyValue, attrcode]);

        return row;
    }

    /**
     * rowIndex 可视数据index
     * @description: 获取单元格属性
     * @param {type}
     * @return:
     */
    getCellProps({
        rowIndex, // 行
        attrcode, // 列
        rowKeyValue,
    } = {}) {
        if (!rowKeyValue && rowIndex === -1) {
            console.warn("rowIndex is -1");
            return;
        }

        // 获取真实的rowIndex
        // !rowKeyValue && (rowKeyValue = this._getRowKeyValue({ rowIndex }));

        let row = this.getStore(["rows"]).query(rowKeyValue || rowIndex);

        return ((row || {})["values"] || {})[attrcode] || {};
    }


    getTableProps = key => {
        let props = this.getStore(['props', key]);
        return props;

    }
    setTableProps = (key, value, shouldForceUpdate = true, callback) => {
        this.setStore(['props', key], value, shouldForceUpdate, callback);
    }

    setStore = (paths = [], value, shouldForceUpdate = true, callback) => {
        if (paths.length) {
            this.store = super.set(paths, value)
        } else {
            this.store = value
        }
        shouldForceUpdate && this.forceUpdate(callback);
        return this.store;
    }
    getStore = (paths = []) => {
        let value = {}
        if (paths.length) {
            value = super.get(paths);
        } else {
            value = this.store
        }
        return value;
    }
    setData = (data = [], shouldForceUpdate = true, callback) => {

        let rowKey = this.getTableProps('rowKey'); // rowid
        // 格式化data
        let rows = isArray(data) ? new TableData(data, rowKey) : this.getData();
        this.setStore(['rows'], rows, shouldForceUpdate, callback);
    }
    getData = () => {
        let rows = this.getStore(['rows']);
        // rows = rows.filter(item => {
        //     return !rule.includes(item.status);
        // })
        return rows || [];
    }

    getFilterData = () => {
        let data = this.getStore(["rows"]);
        // 添加规则什么的  比如删除 比如过滤 TODO 过滤要考虑不能放在status中  这样会影响原来的业务侧的代码
        data = data.filter(item => {
            return !item.filter;
        });
        return data || [];
    }

    getRows = ({ rowIndexs, rowKeyValues } = {}) => {
        let rows = this.getStore(['rows']),
            needRows = [];
        !rowKeyValues && rowIndexs && (rowKeyValues = this._getRowKeyValues({ rowIndexs }));
        (rowKeyValues || rowIndexs || []).forEach(item => {
            let row = rows.query(item);
            !row && console.warn('not find row data');
            needRows.push(row)
        });
        return needRows;
    }


    //通过rowid的方式
    setRows = (data = [], shouldForceUpdate = true) => {
        let rowKey = this.getTableProps('rowKey');
        let rows = this.getData();
        data.forEach(item => {
            if (!item[rowKey]) { console.error('数据中没有rowid') }
            rows.update(item[rowKey], () => {
                return item
            })
        })

        shouldForceUpdate && this.forceUpdate()
    }

    getClickedRow = () => {
        let currentInfo = this.getTableProps("currentInfo")
        return currentInfo
    }

    setClickedRow = (index, shouldForceUpdate = true) => {
        let data = {}
        let rows = this.getStore(['rows'])
        data.record = rows[index]
        data.index = index
        this.setTableProps("currentInfo", data, shouldForceUpdate)
    }

    showColumn = (name, code, isShow) => {
        let attrcode = isArray(code) ? code : [code]
        let meta = this.getMeta()
        if (meta[name]) {
            meta[name].item = meta[name].items.map(item => {
                if (attrcode.includes(item.attrcode)) {
                    item.visible = isShow;
                }
                return item;
            })
        }
        this.setMeta(meta)
    }

    setColumn = (id, attrcodeArr, value, shouldForceUpdate = true) => {
        let storeItem = this.getStore(['meta', id, 'items'])
        if (!storeItem) { return }
        let items = storeItem.get()
        // let itemLen = items.length
        attrcodeArr = isArray(attrcodeArr) ? attrcodeArr : [attrcodeArr]
        attrcodeArr.forEach(attrcode => {
            let item = items.query(attrcode);
            if (item) {
                // TODO 合并数据
                for (let key in value) {
                    item[key] = value[key];
                }
            }
            // for (let i = 0; i < itemLen; i++) {
            //     if (items[i].attrcode === attrcode) {
            //          // 这样就不是引用赋值了
            //         items[i] = { ...items[i], ...value }
            //         break;
            //     }
            // }
        });

        shouldForceUpdate && this.forceUpdate()
    }

    openModal = (type, record, index) => {
        let modelDatas = this.getStore(['modelDatas'])
        let data = {}
        data.showModal = true
        data.operType = type
        if (type == 'edit') {
            data.record = record || {};
            data.index = index || null;
            data.rowId = record ? record.key : this._getRandom()
        } else if (type == 'add') {
            data.record = {};
            data.rowIndex = null;
            data.rowId = this._getRandom()
        }
        modelDatas = { ...modelDatas, ...data }
        this.setStore(['modelDatas'], modelDatas)

    }

    closeModal = () => {
        let modelDatas = this.getStore(['modelDatas'])
        modelDatas.showModal = false
        this.setStore(['modelDatas'], modelDatas)

    }

    setModalValue = (key, data, type) => {
        let modelDatas = this.getStore(['modelDatas'])
        let { record } = modelDatas
        if (type == 'refer') {
            record[key] = {
                display: data.refname,
                value: data.refpk,
            };
        } else {
            record[key] = {
                value: data,
                display: null,
            };
        }
        this.setStore('modelDatas', modelDatas)
    }

    getMeta = id => {
        let meta = this.getStore('meta'),
            res = {};
        if (!meta) return;
        if (id) {
            let viewMeta = this._hasMeta({ id });
            res = viewMeta ? [...viewMeta.items.get()] : [];
        } else {
            for (let key of Object.keys(meta)) {
                // 去除一下浅拷贝 避免JSON.parse报错 
                res[key] = meta[key]; // JSON.parse(JSON.stringify(meta[key]));
                if (isObject(meta[key]) && meta[key].items) {
                    if (meta[key].moduletype === 'table') {
                        res[key].items = meta[key].items.get ? meta[key].items.get() : meta[key].items; // getArrayData
                    }
                }
            }
        }
        return res;
    }

    /**
     * @description: 获取id所在视图的meta
     * @param {type} 
     * @return: 
     */
    getArrayMeta = (id, filter = true) => {
        if (isUndefined(id)) {
            id = this.getStore('name');
            !id && console.warn('not find current meta name');
        }
        let viewMeta = this._hasMeta({ id }) || [];
        // getArrayData
        viewMeta = viewMeta && viewMeta.items && viewMeta.items.getArrayData ? viewMeta.items.getArrayData() : (viewMeta.items || []);
        // console.log(viewMeta);
        return viewMeta.filter(item => {
            let children = item.children;
            let condition = !children || !(filter && isArray(children) && children.length >= 0);
            return condition;
        });
    }

    setMeta = (meta, shouldForceUpdate = true) => {
        this.setStore(['meta'], new TableMeta(meta), shouldForceUpdate);
        console.log(this.getStore('meta'))
    }
    //pattern 有index和rowId
    deleteTableRows = (deleteData, pattern = 'index', shouldForceUpdate = true) => {

        let delItems = [];
        let rowKey = this.getTableProps('rowKey');
        // 这里应该去取过滤之后的数据
        let rows = this.getData();
        let currentInfo = this.getTableProps("currentInfo") || {};
        let pageInfo = this.getStore(['pageInfo']) || {};
        let includeCurrentRow = false;
        let deleteArr = []
        let deleteRowIdArr = []
        let deleteRowPk = []
        let config = this.getConfig()
        let { crossPageSelect, pkname } = config
        let deleteNum = 0
        deleteArr = isArray(deleteData) ? [...deleteData] : [deleteData]

        if (pattern === 'index') {
            deleteRowIdArr = this._getRowIdbyIndex(deleteArr)
        } else {
            deleteRowIdArr = deleteArr
        }

        deleteRowIdArr.forEach(item => {
            if (!includeCurrentRow) { includeCurrentRow = item === currentInfo[rowKey] }
            let len = rows.length
            for (let i = 0; i < len; i++) {
                if (rows[i][rowKey] === item) {
                    (crossPageSelect && pkname) && deleteRowPk.push(rows[i].values[pkname].value);
                    rows.splice(i, 1);
                    deleteNum++
                    break;
                }
            }
        })

        //更新跨页全选的index
        if (crossPageSelect && pkname) {
            let crossPageSelectIndex = this.getStore(['checkInfo', 'crossPageSelectIndex'])

            deleteRowPk.forEach(pk => {
                crossPageSelectIndex.has(pk) && crossPageSelectIndex.delete(pk)
            })
            this.setStore(['checkInfo', 'crossPageSelectIndex'], crossPageSelectIndex, false)
        }
        // 更新总共条数
        //解决 NCC-44100
        if (pageInfo.total) {
            pageInfo.total -= deleteNum;
        }
        this.setStore(['pageInfo', pageInfo, false])
        // 更新check全选半选状态
        this.setSelect(deleteRowIdArr, 'delete')

        // 删除自动选中到下一个行的逻辑
        if (includeCurrentRow) {
            this.setTableProps("currentInfo", null, false);
            this.setTableProps('focusIndex', -1, false);
        }
        // 更新表格数据——bbq
        this.setData(rows);
        return delItems;
    }

    addTableRows = (addData, index, shouldForceUpdate = true) => {
        let rows = this.getStore(['rows'])
        let checkedAll = this.getStore(['checkInfo', 'checkedAll'])
        let newArr = JSON.parse(JSON.stringify(addData));
        newArr = newArr.map(row => {
            row.rowId = row.rowId || this._getRandom();
            return row;
        });
        index = index || 0
        rows.splice(index, 0, ...newArr)
        //更新全选半选状态
        if (checkedAll) {
            this.setStore(['checkInfo', 'checkedAll'], false, false)
            this.setStore(['checkInfo', 'indeterminate'], true, false)
        }
        this.setData(rows, shouldForceUpdate ? true : false)
        // shouldForceUpdate && this.forceUpdate();
    }

    // 设置选中行 value是rowid的数组
    setSelect = (value, pattern = 'set', shouldForceUpdate = true, isDiabledChecked) => {
        let selectIndex = this.getStore(['checkInfo', 'selectIndex']);
        let indeterminate = false, checkedAll = false;

        if (!isArray(value)) { value = [value] }
        if (pattern === 'set') {
            selectIndex.clear();
            value.forEach(rowId => {

                selectIndex.add(rowId);
            })
        }
        if (pattern === 'add') {
            value.forEach(rowId => {
                selectIndex.add(rowId);
            })
        }
        if (pattern === 'delete') {
            value.forEach(rowId => {
                selectIndex.delete(rowId)
            })
        }
        if (pattern === 'clear') {
            selectIndex.clear();
            this.setTableProps('focusIndex', -1, false)
        }

        let checkInfo = this.getStore('checkInfo');
        // 获取当前视图
        let rows = this.getFilterData();
        let rowsLength = rows.length;
        let sagaLen = 0;

        rowsLength && rows.map(item => {
            let values = item.values || item;
            values.selected = values.selected || {};
            let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
            let disabled = isDiabledChecked || (item['disabled'] ? item['disabled'] : (values['disabled'] && values['disabled'].value));
            // 记录一下saga的数目
            !unSaga && (sagaLen++);

            if (disabled) {
                if (item.selected) {
                    selectIndex.add(item.rowId)
                } else {
                    selectIndex.delete(item.rowId)
                }
            } else if ((pattern === 'all' || pattern === 'allpage') && unSaga) {
                selectIndex.add(item.rowId)
                item.selected = true
                values.selected.value = true
            } else if (pattern === 'reverse') {
                if (selectIndex.has(item.rowId)) {
                    selectIndex.delete(item.rowId)
                    item.selected = false
                    values.selected.value = false
                } else {
                    if (unSaga || !disabled) {
                        selectIndex.add(item.rowId)
                        item.selected = true
                        values.selected.value = true
                    }
                }
            } else {
                let isSelect = selectIndex.has(item.rowId)
                isSelect && unSaga ? item.selected = true : item.selected = false
                isSelect && unSaga ? values.selected.value = true : values.selected.value = false
            }
        })
        let selectSize = selectIndex.size
        if (selectSize === 0) {
            indeterminate = false
            checkedAll = false
        } else if (selectSize > 0 && (selectSize + sagaLen) < rowsLength) {
            indeterminate = true
            checkedAll = false
        } else if ((selectSize + sagaLen) === rowsLength) {
            indeterminate = false
            checkedAll = true
        }
        checkInfo.indeterminate = indeterminate
        checkInfo.checkedAll = checkedAll
        checkInfo.selectIndex = selectIndex
        if (pattern === 'allpage') {
            checkInfo.checkedAllpage = true;
        } else {
            checkInfo.checkedAllpage = false;
        }
        // this.setData(rows, false);  // 这里不需要更新数据  引用已经更新  如果更新数据  会导致原始数据发生变化
        this.setStore(['checkInfo'], checkInfo, shouldForceUpdate);
    }

    setCellValue = ({ value, rowId, index, attrcode, shouldForceUpdate = true }) => {
        let id;
        if (!isUndefined(index)) {
            if (isString(index)) {
                index = Number(index)
            }
            id = this._getRowIdbyIndex([index])[0]
        }
        if (rowId) {
            id = rowId
        }
        let rows = this.getStore(['rows'])
        rows.update(id, oldRow => {
            oldRow.values[attrcode] = value
            return oldRow;
        });
        shouldForceUpdate && this.forceUpdate();//改成整表更新
    }

    getConfig = () => {
        return this.getStore(['config'])
    }
    setConfig = config => {
        this.setStore(['config'], config, false)
    }

    // 清空排序
    clearSortStatus({ columns }) {
        columns.forEach(eve => {
            //获取表格列配置
            const { children } = eve;
            if (!isUndefined(children)) {
                //判断和并列的情况
                this.clearSortStatus({ columns: children });
            } else {
                columns.forEach(item => {
                    //保存返回的column状态，没有则终止order状态
                    item.order = 'flatscend';
                    item.orderNum = '';
                });
            }
        });
    }

    /**
  * @description: 设置缓存
  * @param {type} 
  * @return: 
  */
    setCache = (path, value) => {
        if (!path) {
            console.warn('invalid params <path> to setting');
            return;
        }
        if (!isArray(path)) {
            path = [path];
        }
        let current = this.cache;
        let len = path.length;
        path.forEach((item, index) => {
            if (!current[item]) {
                current[item] = {};
            }

            if (index === len - 1) {
                current[item] = value;
            } else {
                current = current[item];
            }
        });
    }

    /**
    * @description: 获取缓存
    * @param {type} 
    * @return: 
    */
    getCache = paths => {
        if (isUndefined(paths)) {
            console.warn('invalid params <paths> to setting');
            return;
        }
        if (!isArray(paths)) {
            paths = [paths];
        }

        let current = this.cache;

        paths.some(item => {
            if (isUndefined(item) || isNullOrVoid(current)) {
                isUndefined(item) && console.warn('paths has empty item[%s]', paths);
                return true;
            }

            if (!current[item]) {
                current = null;
                return true;
            }
            current = current[item];

            return false;
        });
        // console.log(this.cache);
        return current;
    }

    //跨页全选的crossPage
    setCrossPage = (rows, pkname, isSelect) => {
        let { crossPageSelectIndex } = this.getStore('checkInfo');
        let rowsArr = isArray(rows) ? rows : [rows];
        rowsArr.forEach(row => {
            let pk
            if (isObject(row.values)) {
                pk = row.values[pkname].value;
            } else {
                pk = row[pkname].value;
            }
            let values = row.values || row;
            let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
            if (isSelect && unSaga) {
                crossPageSelectIndex.add(pk);
            } else {
                crossPageSelectIndex.delete(pk);
            }
        });
    };

    _hasMeta({ id } = {}) {
        let meta = this.getStore('meta') || {};
        if (!id || !isObject(meta[id])) { return null; }
        return meta[id];
    }
    _getRandom() {
        return String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12);
    }
    _getRowIdbyIndex = indexArr => {
        let rows = this.getData()
        let rowIdArr = []
        rows.forEach((row, index) => {
            let strIndex = String(index)
            if (indexArr.includes(index) || indexArr.includes(strIndex)) {
                rowIdArr.push(row.rowId)
            }
        })
        return rowIdArr
    }

}

export const useSimpleTable = hookFactory(simpleTableStore);